import { globalEvents, zoneConfig } from '@rx-angular/cdk/zone-configurations'

// ***** CURRENT SETUP *****

// zoneConfig.global.disable.requestAnimationFrame()
zoneConfig.global.disable.IntersectionObserver()
zoneConfig.global.disable.MutationObserver()

zoneConfig.events.disable.UNPATCHED_EVENTS([...globalEvents.filter(item => item !== 'load' && item !== 'error')])
// temp filter for load & error events for old code on | async in html
// other events to check - focusEvents, mouseEvents, wheelEvents, inputEvents, keyboardEvents, touchEvents
// TODO: check all | async
// zoneConfig.unpatchXHR() - for disabling all xhr events
// zoneConfig.global.disable.EventEmitter()
// ***** OLD SETUP END *****

// TODO: reconsider & fulfill
// ***** NEW SETUP *****

/*
 * -------------
 * here you can find all existing zone's disables: https://github.com/angular/angular/blob/master/packages/zone.js/MODULE.md
 * and here all implemented wrappers for them presented in rx-angular/cdk - https://github.com/rx-angular/rx-angular/blob/master/libs/cdk/zone-configurations/src/lib/model/zone.configurations.api.ts
 * -------------
 * */

/* cannot disable now, cause' some things in angular and libs are expecting to zone updates */
// ----
// zoneConfig.global.disable.EventTarget() /* [addEventListener(xxx)] */
// zoneConfig.global.disable.timers() /* [setTimeout, setInterval] */
// zoneConfig.global.disable.ZoneAwarePromise() /* [Promise => then/catch ] */
// ----

// START with these
// ----
// zoneConfig.global.disable.requestAnimationFrame()
// zoneConfig.global.disable.FileReader()
// zoneConfig.global.disable.MutationObserver()
// zoneConfig.global.disable.IntersectionObserver()
// zoneConfig.global.disable.blocking() /* [alert/prompt/confirm] */
// zoneConfig.global.disable.on_property() /* [onProperty API, such `onclick="someFunc()"`] */
// zoneConfig.global.disable.customElements() /* [customElements API, avoid trigger zone for customElements.define()] */
// zoneConfig.global.disable.geolocation() /* [geolocation browser API] */
// zoneConfig.global.disable.canvas() /* [canvas browser API] */
// // zoneConfig.global.disable.XHR() /* [we are using shorthand `unpatchXHR` below] */
//
// // not presented in rx-angular/cdk for current moment: IE BrowserTools check, CrossContext check, mediaQuery, notification, MessagePort
//
// zoneConfig.global.disable.DISABLE_WRAPPING_UNCAUGHT_PROMISE_REJECTION() /* [uncaught promise rejection] */
// zoneConfig.runtime.disable.ignoreConsoleErrorUncaughtError() /* [console.error(...) ] */
//
// // temp filter for load & error events for old code on | async in html
// // other events to check - focusEvents, mouseEvents, wheelEvents, inputEvents, keyboardEvents, touchEvents
// zoneConfig.events.disable.UNPATCHED_EVENTS([...globalEvents.filter(item => item !== 'load' && item !== 'error')])
//
// zoneConfig.unpatchXHR() // shorthand for zoneConfig.global.disable.XHR() + zoneConfig.events.disable.UNPATCHED_EVENTS(...xhrEvents) [https://github.com/rx-angular/rx-angular/blob/master/libs/cdk/zone-configurations/src/lib/convenience-methods.ts]
// zoneConfig.useUnpatchedPassiveScrollEvents() // shorthand for config.events.disable.PASSIVE_EVENTS(['scroll']) + config.events.disable.UNPATCHED_EVENTS(['scroll']) [https://github.com/rx-angular/rx-angular/blob/master/libs/cdk/zone-configurations/src/lib/convenience-methods.ts]
// ----

// for legacy browsers, don't touch them
// ----
// zoneConfig.global.disable.registerElement()
// zoneConfig.global.disable.EventTargetLegacy()
// zoneConfig.global.disable.defineProperty()
// ----

// node's disables, don't touch them now
// ----
// zoneConfig.global.disable.EventEmitter()
// zoneConfig.global.disable.fs()
// zoneConfig.global.disable.crypto()
// zoneConfig.global.disable.node_timers()
// zoneConfig.global.disable.nextTick()
// ----
